import WUGUOCHENG from "@/assets/img/teacher/wuguocheng.png";
import GAOYUNPENG from "@/assets/img/teacher/gaoyunpeng.png";
import LIXIUZHEN from "@/assets/img/teacher/lixiuzhen.png";
import HUHAIBO from "@/assets/img/teacher/huhaibo.png";
import CAOXUE from "@/assets/img/teacher/caoxue.png";
import BIXIMING from "@/assets/img/teacher/biximing.png";
import JIANGCHANGQING from "@/assets/img/teacher/jiangchangqing.png";
import SHIJIE from "@/assets/img/teacher/shijie.png";
import XUJUN from "@/assets/img/teacher/xujun.png";
import YUEXIAODONG from "@/assets/img/teacher/yuexiaodong.png";
import ZHANGHONGYU from "@/assets/img/teacher/zhanghongyu.png";
import LIUMINGXIN from "@/assets/img/teacher/liumingxin.png";
import LIYONG from "@/assets/img/teacher/liyong.png";
import HOURUIHE from "@/assets/img/teacher/houruihe.png";
import SHAOCHANGQING from "@/assets/img/teacher/shaochangqing.png";
import ZHANGYAMING from "@/assets/img/teacher/zhangyaming.png";
import PENGYUZHEN from "@/assets/img/teacher/pengyuzhen.png";
import QIANGGUANGFENG from "@/assets/img/teacher/qiangguangfeng.png";



import notice1 from '@/assets/img/中国心理卫生协会官网通知.png'
import notice2 from '@/assets/img/合作机构管理细则.jpg'
import notice3 from '@/assets/img/家庭教育考试安排.png'
import notice4 from '@/assets/img/假证！虚假宣传.jpg'
import notice5 from '@/assets/img/心理咨询师考试安排.png'


import course1 from '@/assets/img/mb_img/bg_心理咨询专业技能培训.png'
import course2 from '@/assets/img/mb_img/bg_家庭教育指导师专业技能培训.png'
import course3 from '@/assets/img/mb_img/bg_线上精品课程.png'



import HEPOLICIE from '@/assets/img/mb_img/policie/hePolicie.png'
import HEPOLICIE2 from '@/assets/img/mb_img/policie/hePolicie2.png'
import PCPOLICIE from '@/assets/img/mb_img/policie/pcPolicie1.png'
import PCPOLICIE2 from '@/assets/img/mb_img/policie/pcPolicie2.png'
import PCPOLICIE3 from '@/assets/img/mb_img/policie/pcPolicie3.png'

import COURSEIMG1 from '@/assets/img/courseImg/抑郁专题商品图.jpg';
import COURSEIMG2 from '@/assets/img/courseImg/婴幼儿身心发展商品图.jpg';
import COURSEIMG3 from '@/assets/img/courseImg/家庭心理辅导技能商品图.jpg';
import COURSEIMG4 from '@/assets/img/courseImg/儿童行为习惯与思维习惯培养商品图.jpg';
import COURSEIMG5 from '@/assets/img/courseImg/儿童学习能力培养商品图.jpg';
import COURSEIMG6 from '@/assets/img/courseImg/小学儿童身心发展及教育方式.jpg';
import COURSEIMG7 from '@/assets/img/courseImg/青春期身心发展及教育方式商品图.jpg';
import COURSEIMG8 from '@/assets/img/courseImg/青春期人格品质的塑造商品图.jpg';
import COURSEIMG9 from '@/assets/img/courseImg/亲自陪伴实操技能商品图.jpg';
import COURSEIMG10 from '@/assets/img/courseImg/良好亲子沟通实操技能商品图.jpg';
import COURSEIMG11 from '@/assets/img/courseImg/家庭教育工作者的“法宝”商品图.jpg';
import COURSEIMG12 from '@/assets/img/courseImg/家庭教育工作方法商品图.jpg';
import COURSEIMG13 from '@/assets/img/courseImg/父母教养方式对孩子的影响商品图.jpg';
import COURSEIMG14 from '@/assets/img/courseImg/提高家庭教育指导的时效性商品图.jpg';
import COURSEIMG15 from '@/assets/img/courseImg/儿童的社会性商品图.jpg';
import COURSEIMG16 from '@/assets/img/courseImg/儿童六大商品图.jpg';
import COURSEIMG17 from '@/assets/img/courseImg/儿童青少年常见心理问题的识别.jpg';
import COURSEIMG18 from '@/assets/img/courseImg/心理评估课程图.jpg';
import COURSEIMG19 from '@/assets/img/courseImg/专业伦理与案例督导.jpg';

const noticeList = [{
  name: '中国心理卫生协会官网通知',
  date: '2023.09.13',
  pic: notice1,
}, {
  name: '合作机构管理细则',
  date: '2023.09.13',
  pic: notice2,
}, {
  name: '心理咨询师考试安排',
  date: '2024.10.08',
  pic: notice5,
}, {
  name: '家庭教育考试安排',
  date: '2023.12.01',
  pic: notice3,
}, {
  name: '假证！虚假宣传... ...',
  date: '2022.05.11',
  pic: notice4,
}]
const teacherList = [
  // {
  //   name_zh: "武国城",
  //   name_cn: "WUGUOCHENG",
  //   pic: WUGUOCHENG,
  //   titles: [
  //     "空军军医大学教授",
  //     "空军特色医学中心研究员",
  //     "中国心理卫生协会名誉理事长",
  //     "中国心理学会心理咨询师<br />工作委员会副主任委员",
  //   ],
  // },
  {
    name_zh: "高云鹏",
    name_cn: "GAOYUNPENG",
    pic: GAOYUNPENG,
    titles: [
      "著名心理学家",
      "北京大学心理系教授",
      "北京大学心理培训中心主任",
      "心理咨询师国家职业<br />资格培训教程编委",
    ],
  },
  {
    name_zh: "李秀珍",
    name_cn: "LIXIUZHEN",
    pic: LIXIUZHEN,
    titles: [
      "全军临床心理专业委员会常委",
      "全国健康心理专业<br />委员会专家成员",
      "全国心理咨询与治疗<br />专业委员会委员",
      "北京军区医学心理专<br />业委员会主任委员",
    ],
  },
  // {
  //   name_zh: "曹雪",
  //   name_cn: "CAOXUE",
  //   pic: CAOXUE,
  //   titles: [
  //     "毕业于中国人民大学哲学系",
  //     "国家心理咨询师培训教师",
  //     "心理咨询师协会（CAPA）<br />筹委会工作委员会委员",
  //     "国家二级心理咨询师",
  //     "系统式治疗取向心理咨询师"
  //   ],
  // },
  {
    name_zh: "姜长青",
    name_cn: "JIANGCHANGQING",
    pic: JIANGCHANGQING,
    titles: [
      "中国心理卫生协会常务理事",
      "北京安定医院主任心理师",
      "劳动和社会保障部<br />心理咨询专家委员会委员",
    ],
  },
  {
    name_zh: "史杰",
    name_cn: "SHIJIE",
    pic: SHIJIE,
    titles: [
      "主任医师<br />教授<br />研究生导师",
      "中国心理卫生协会心理咨询师<br />专业委员会副主任委员<br />心理咨询督导师",
    ],
  },
  {
    name_zh: "徐军",
    name_cn: "XUJUN",
    pic: XUJUN,
    titles: [
      "国家二级心理咨询师",
      "湖南省家庭教育研究会理事",
      "中国心理卫生协会<br />注册心理咨询师",
      "国家婚姻家庭咨询师<br />职业资格考评员",
    ],
  },
  {
    name_zh: "岳晓东",
    name_cn: "YUEXIAODONG",
    pic: YUEXIAODONG,
    titles: [
      "中国著名心理学家",
      "哈佛大学心理学博士",
      "中国儿童中心副研究员",
      "香港城市大学教授",
      "首都师范大学特聘教授",
      "香港心理学会<br />辅导分会首任会长",
    ],
  },
];
const pcLecturer = [
  // {
  //   name_zh: "武国城",
  //   name_cn: "WUGUOCHENG",
  //   pic: WUGUOCHENG,
  //   titles: [
  //     "空军军医大学教授",
  //     "空军特色医学中心研究员",
  //     "中国心理卫生协会名誉理事长",
  //     "中国心理学会心理咨询师<br />工作委员会副主任委员",
  //   ],
  // }, 
  {
    name_zh: "高云鹏",
    name_cn: "GAOYUNPENG",
    pic: GAOYUNPENG,
    titles: [
      "著名心理学家",
      "北京大学心理系教授",
      "北京大学心理培训中心主任",
      "心理咨询师国家职业<br />资格培训教程编委",
    ],
  }, {
    name_zh: "李秀珍",
    name_cn: "LIXIUZHEN",
    pic: LIXIUZHEN,
    titles: [
      "全军临床心理专业委员会常委",
      "全国健康心理专业<br />委员会专家成员",
      "全国心理咨询与治疗<br />专业委员会委员",
      "北京军区医学心理专<br />业委员会主任委员",
    ],
  },
  // {
  //   name_zh: "胡海波",
  //   name_cn: "HUHAIBO",
  //   pic: HUHAIBO,
  //   titles: [
  //     "国家心理咨询师专家组成员",
  //     "中国心理卫生协会会员",
  //     "北京心理卫生协会<br />社区心理工作专业委员会委员",
  //     "IHNMA/WMECC催眠保健治疗",
  //   ],
  // },
  {
    name_zh: "毕希名",
    name_cn: "BIXIMING",
    pic: BIXIMING,
    titles: [
      "心理咨询师",
      "国家心理咨询师培训教材编委",
      "青岛大学医学院教授",
      "研究生导师",
      "著名心理学家",
    ],
  },
  // {
  //   name_zh: "曹雪",
  //   name_cn: "CAOXUE",
  //   pic: CAOXUE,
  //   titles: [
  //     "毕业于中国人民大学哲学系",
  //     "国家心理咨询师培训教师",
  //     "心理咨询师协会（CAPA）<br />筹委会工作委员会委员",
  //     "国家二级心理咨询师",
  //     "系统式治疗取向心理咨询师"
  //   ],
  // },
  {
    name_zh: "姜长青",
    name_cn: "JIANGCHANGQING",
    pic: JIANGCHANGQING,
    titles: [
      "中国心理卫生协会常务理事",
      "北京安定医院主任心理师",
      "劳动和社会保障部<br />心理咨询专家委员会委员",
    ],
  },
  {
    name_zh: "史杰",
    name_cn: "SHIJIE",
    pic: SHIJIE,
    titles: [
      "主任医师<br />教授<br />研究生导师",
      "中国心理卫生协会心理咨询师<br />专业委员会副主任委员<br />心理咨询督导师",
    ],
  },
]
const heLecturer = [{
  name_zh: "姜长青",
  name_cn: "JIANGCHANGQING",
  pic: JIANGCHANGQING,
  titles: [
    "中国心理卫生协会常务理事",
    "北京安定医院主任心理师",
    "劳动和社会保障部<br />心理咨询专家委员会委员",
  ],
}, {
  name_zh: "岳晓东",
  name_cn: "YUEXIAODONG",
  pic: YUEXIAODONG,
  titles: [
    "中国著名心理学家",
    "哈佛大学心理学博士",
    "中国儿童中心副研究员",
    "香港城市大学教授",
    "首都师范大学特聘教授",
    "香港心理学会<br />辅导分会首任会长",
  ],
}, {
  name_zh: "张宏宇",
  name_cn: "ZHANGHONGYU",
  pic: ZHANGHONGYU,
  titles: [
    "北师大心理学博士、副教授",
    "心理动力学取向心理咨询师",
    "军委科技进步二等奖",
    "北京市教育教学成果一等奖",
    "心理咨询个案5000+小时",
    "北京市教育工委心理健康教育先进个人等多项奖项",
  ],
}, {
  name_zh: "柳铭心",
  name_cn: "LIUMINGXIN",
  pic: LIUMINGXIN,
  titles: [
    "中国儿童中心副研究员",
    "发展与教育心理学博士",
    "心理健康重点实验室博士后",
    "国家二级心理咨询师",
  ],
}, {
  name_zh: "李勇",
  name_cn: "LIYONG",
  pic: LIYONG,
  titles: [
    "心理学硕士",
    "国际教育学博士",
    "中国心理卫生协会<br />注册心理咨询师",
    "中国心理学会<br />心理服务委员会委员",
  ],
}, {
  name_zh: "侯瑞鹤",
  name_cn: "HOURUIHE",
  pic: HOURUIHE,
  titles: [
    "中国人民大学<br />心理咨询中心副教授",
    "发展与教育心理学博士",
    "中国心理学会注册心理师",
    "萨提亚家庭治疗受训导师",
  ],
}, {
  name_zh: "徐军",
  name_cn: "XUJUN",
  pic: XUJUN,
  titles: [
    "国家二级心理咨询师",
    "湖南省家庭教育研究会理事",
    "中国心理卫生协会<br />注册心理咨询师",
    "国家婚姻家庭咨询师<br />职业资格考评员",
  ],
}, {
  name_zh: "邵长青",
  name_cn: "SHAOCHANGQING",
  pic: SHAOCHANGQING,
  titles: [
    "国家二级心理咨询师",
    "国家基础教育实验中心<br />与家庭教育研究所特聘专家",
    "10000+小时个案咨询<br />和团体督导经验",
  ],
}, {
  name_zh: "张雅明",
  name_cn: "ZHANGYAMING",
  pic: ZHANGYAMING,
  titles: [
    "《中小学心理健康》丛书主编",
    "中科院心理所博士，教授",
    "资深心理咨询专家",
    "心理咨询咨询督导师",
  ],
}, {
  name_zh: "彭玉珍",
  name_cn: "PENGYUZHEN",
  pic: PENGYUZHEN,
  titles: [
    "国家二级心理咨询师",
    "婚姻家庭咨询师",
    "湖南省健康管理学会理事",
    "湖南省心理健康教育与咨询<br />专委会副主任",
  ],
}, {
  name_zh: "强光峰",
  name_cn: "QIANGGUANGFENG",
  pic: QIANGGUANGFENG,
  titles: [
    "中国教育学会培训部主任",
    "原山东省泰安市教育局副局长",
    "正高级教师,特级教师",
    "曲阜师范大学教授",
  ],
}]
const courseList = [{
  bgImg: course1,
  title: '心理咨询专业技能培训',
  path: '/psychologicalConsulting'
}, {
  bgImg: course2,
  title: '家庭教育指导师专业技能培训',
  path: '/homeEducation'
}, 
{
  bgImg: course3,
  title: '线上精品课程',
  path: '/excellentCourses'
}
]
const heCourseSyllabus = [{
  title: '家庭教育指导概述',
  title_en: 'OVERVIEW FAMILY DUCATION',
  list: [{
    name: '家庭教育相关政策与法律法规'
  }, {
    name: '家庭教育基础知识'
  }, {
    name: '家庭教育实操技能'
  }]
}, {
  title: '家庭教育相关知识和基本理论',
  title_en: 'BASIC THEORY',
  list: [{
    name: '家长家庭教育指导'
  }, {
    name: '儿童身心发展规律和家庭教育科学方法'
  }]
}, {
  title: '家庭教育指导实践与工作实务',
  title_en: 'GUIDE PRACTICE',
  list: [{
    name: '家庭教育实践技能'
  }, {
    name: '家庭教育指导工作实务'
  }]
}, {
  title: '心理评估',
  title_en: 'PSYCHOLOGICAL ASSESSMENT',
  list: [{
    name: '心理评估初级'
  }, {
    name: '心理评估中级'
  }]
}]
const pcCourseSyllabus = [{
  title: '基础知识',
  title_en: 'BASIC KNOWLEDGE',
  list: [{
    name: '基础心理学'
  }, {
    name: '社会心理学'
  }, {
    name: '发展心理学'
  }, {
    name: '变态与健康心理学'
  }, {
    name: '咨询心理学'
  }]
}, {
  title: '操作技能',
  title_en: 'SKILL OPERATION',
  list: [{
    name: '心理诊断'
  }, {
    name: '心理测量'
  }, {
    name: '心理咨询技能'
  }]
}, {
  title: '职业伦理',
  title_en: 'PROFESSIONAL ETHICS',
  list: [{
    name: '心理咨询职业伦理'
  }, {
    name: '精神卫生法相关知识'
  }]
}, {
  title: '心理咨询和评估',
  title_en: 'COUNSELING AND EVALUATION',
  list: [{
    name: '心理评估流程和技巧'
  }, {
    name: '心理评估量表的使用'
  }, {
    name: '心理评估的基本内容和方法'
  }]
}]
const pcPolicieList = [{
  pic: PCPOLICIE
},{
  pic: PCPOLICIE2
},{
  pic: PCPOLICIE3
}]
const hePolicieList = [{
  pic: HEPOLICIE2
},{
  pic: HEPOLICIE
}]
const fqaList = [{
  question: '1．本项目设立的初衷是什么？',
  answer: '国家取消心理咨询师职业资格认证后，社会各界学习心理健康知识和心理咨询技能的热情依然很高。广大心理培训机构和心理学爱好者希望有一种心理咨询师考证培训的替代产品，继续承担起普及心理健康知识，培养心理服务人才的任务。在此背景下，中国心理卫生协会在人社部国家心理咨询师专家委员会专家的指导下，组织协会内外专家开发了“心理咨询师专业技能培训项目”，继续满足社会各界学习心理健康知识和心理咨询技能的入门需要。'
}, {
  question: '2. 中国心理卫生协会的优势是什么？',
  answer: '中国心理卫生协会作为原人社部心理咨询师职业资格考试的技术支持单位，拥有一支长期从事心理咨询培训和鉴定的专家队伍。 中国心理卫生协会是国家一级行业协会，是国家精神卫生和心理健康领域最大的也是最权威的行业管理组织。 原人社部国家心理咨询师专家委员会即为人社部鉴定中心委托中心协组建，原心理咨询师国家培训教程也是中心协和中国就业培训技术指导中心联合组织编写。'
}, {
  question: '3. 该项目是心理咨询师认证吗？',
  answer: '不是认证，取得的证书也不是职业资格证书。这是一个心理咨询师专业技能的培训项目。颁发的培训合格证书可以作为持证者受过心理学知识和心理咨询技能培训的有效证明，也可以作为持证者开展心理服务的资格参考。'
}, {
  question: '4. 培训合格者相当于原人社部心理咨询师认证的哪个级别？',
  answer: '本项目合格者颁发的是培训合格证书，课程设计参考了《国家心理咨询师职业标准》，内容比原来的考证培训更丰富，更有针对性和实用性。课程包含原来三级教材的内容，并新增部分内容。培训合格者相当于原来国考认证的3级+的水平。'
}, {
  question: '5. 已经取得人社部证书的心理咨询师还需要学习本课程么？',
  answer: '已经取得国家心理咨询师证书者不是本项目的目标人群。'
}, {
  question: '6. 参加基础培训取得合格证书后能从事心理咨询工作吗？',
  answer: '心理咨询现在是非准入行业，国家并没有明确具备什么样的资格才能从事心理咨询工作。不过心理咨询是一种专业性非常强的工作，从业者须恪守职业伦理，有扎实的心理学及相关学科的理论基础，接受过系统的咨询方法和技能训练，最好在专业督导的支持下，经过长时间的个人体验和个案积累，才能独立从事专业的心理咨询工作。参加基础培训取得合格证书者，还不具备独立执业心理咨询的能力，但可以从事一些基础的心理服务工作或在专业咨询师和督导的指导下做一些心理咨询的辅助工作。'
}, {
  question: '7. 想继续专业成长和从业的学员还要参加哪些培训？',
  answer: '学员在取得本项目培训合格证书后，如果想继续专业成长并从业，可以参加一些心理咨询基本功训练课程，咨询方法和技术的专项课程，以及面向专业服务的实战训练。如果具备条件，建议学员寻求通过咨询心理学的学历教育和准学历教育来提高素质，实现专业和职业成长。'
}, {
  question: '8. 培训用什么教材？',
  answer: '本项目的培训教材以《国家心理咨询师职业资格培训教程》 (2012 版)为蓝本，吸收各方面的意见，编辑而成。'
}, {
  question: '9. 项目对接培训机构可以宣传本培训是中国心理卫生协会组织的吗？',
  answer: '不可以。项目为中国心理卫生协会组织专家策划，但招生和培训由合作机构自己组织。培训机构按照项目所设定的培训课时和培训内容来组织有质量的培训，并代学员报名参加中心协项目部组织的考试，协会对考试合格者颁发培训合格证书。学员和培训机构发生经济和法律关系，和中国心理卫生协会没有直接联系。协会为取证者提供证书查询服务和力所能及的执业支持服务，取证者未来也可以优先参与协会组织的各类培训。'
}, {
  question: '10. 培训方式和培训课时有统一要求吗？',
  answer: '培训方式可以面授也可以网络授课+面授，不能是纯网络授课方式，因为有些技能课，需要现场交互和体验。项目要求 192 培训课时，可以多，不能少。培训机构可以根据自己的优势和学员的要求安排一些补充课程。完成项目安排的192 课时学习，是学员可以参加项目综合考试的必要条件。合作机构的培训课表和师资、学员材料，要提交中国心理卫生协会项目部备案，项目部通过各种方式特别是学员调查核实执行情况。'
}, {
  question: '11. 学员报名参加培训有学历要求吗？',
  answer: '心理咨询师专业技能培训是普及性培训和入门性培训，目的在于普及心理学和心理健康知识。从其定位来讲，凡是有意愿学习了解相关知识和技能的人都可以学习，没有学历学位要求;但是，对有志于从事心理咨询相关工作和职业发展的人员而言，还需要参加后续的进阶培训和专项技能培训，必须有学历门槛。按照现有的《心理咨询师国家职业标准》，三级心理咨询师的报名条件是:具有心理学、教育学、医学专业的大专以上学历，或是具有其他专业本科以上学历。培训机构可以自主设定本培训招生条件；但如果学员想参加一些专项技能培训并被政府主管部门和行业协会认可从业资格，应具备最低的学历要求。就现阶段的国家标准来说，就是必须具备心理学、教育学、医学专业的大专以上学历或是其他专业本科以上学历。'
}, {
  question: '12. 考试方式和证书？',
  answer: '考试分为综合考试和论文两部分。由协会项目部统一组织综合考试，培训机构协助实施。初定每季度考一次，每季度末月的第三周的周六上午举行。考试时间120分钟。综合考试采取百分制，理论题约占60%，技能题约占 40%，总分60分为合格线。论文由培训机构参照评分规范组织评价赋分，在综合考试后一周内完成。论文采取百分制，60分为合格线。培训机构负责地面考场和考务，协会项目部派人员监督。项目部将在考前三周提供综合考试样卷，供学员熟悉题型。学员总成绩=综合考试成绩*70%+论文成绩*30%，总成绩小数部分四舍五入，仅保留整数。考试未通过，1 年内可重考。考试通过者，获得《心理咨询师专业技能培训证书》。成绩单和证书由中心协统一签发。'
}, {
  question: '13. 考试报名是什么时候，需要提交什么样的材料？',
  answer: '考试由培训机构代学员集体报名，不接受学员个人报名。培训机构须为每位考生提交完整的 《心理咨询师专业技能培训综合考试报名表》和有对接机构盖章的学员考勤记录 (须有学员签名)。'
}, {
  question: '14. 考场安排在哪里？',
  answer: '参照以往人社部心理咨询师考试的标准设置考场，考场由培训机构负责安排。考场适度集中，就近安排，项目部派人员监督。对接机构在考试前两周将考场和考务安排报项目部备案。疫情期间，综合考试临时采用网络形式，除了没有实体考场外，其它要求和地面考试一致。'
}]
const excellentCourseList = [{
  title: '抑郁专题课程',
  img: COURSEIMG1,
  url: 'http://xinlizixun.aijiad.com/detail/24453'
},{
  title: '婴幼儿的身心发展及教育方式',
  img: COURSEIMG2,
  url: 'http://xinlizixun.aijiad.com/detail/24448'
},{
  title: '家庭心理辅导技能',
  img: COURSEIMG3,
  url: 'http://xinlizixun.aijiad.com/detail/24446'
},{
  title: '儿童行为习惯与思维的培养',
  img: COURSEIMG4,
  url: 'http://xinlizixun.aijiad.com/detail/24444'
},{
  title: '儿童学习能力培养',
  img: COURSEIMG5,
  url: 'http://xinlizixun.aijiad.com/detail/24443'
},{
  title: '小学儿童身心发展及教育方式',
  img: COURSEIMG6,
  url: 'http://xinlizixun.aijiad.com/detail/24442'
},{
  title: '青春期身心发展及教育方式',
  img: COURSEIMG7,
  url: 'http://xinlizixun.aijiad.com/detail/24441'
},{
  title: '青春期人格品质的塑造',
  img: COURSEIMG8,
  url: 'http://xinlizixun.aijiad.com/detail/24440'
},{
  title: '亲子陪伴实操技能',
  img: COURSEIMG9,
  url: 'http://xinlizixun.aijiad.com/detail/24439'
},{
  title: '良好亲子沟通实操技能',
  img: COURSEIMG10,
  url: 'http://xinlizixun.aijiad.com/detail/24438'
},{
  title: '家庭教育工作者的“法宝”',
  img: COURSEIMG11,
  url: 'http://xinlizixun.aijiad.com/detail/24436'
},{
  title: '家庭教育工作方法',
  img: COURSEIMG12,
  url: 'http://xinlizixun.aijiad.com/detail/24435'
},{
  title: '父母教养方式对孩子的影响',
  img: COURSEIMG13,
  url: 'http://xinlizixun.aijiad.com/detail/24434'
},{
  title: '提高家庭教育指导的时效性',
  img: COURSEIMG14,
  url: 'http://xinlizixun.aijiad.com/detail/24433'
},{
  title: '儿童的社会性',
  img: COURSEIMG15,
  url: 'http://xinlizixun.aijiad.com/detail/24429'
},{
  title: '儿童的六大核心心理能力',
  img: COURSEIMG16,
  url: 'http://xinlizixun.aijiad.com/detail/24428'
},{
  title: '儿童青少年常见心理问题的识别',
  img: COURSEIMG17,
  url: 'http://xinlizixun.aijiad.com/detail/24366'
},{
  title: '心理评估课程',
  img: COURSEIMG18,
  url: 'http://xinlizixun.aijiad.com/detail/23865'
},{
  title: '专业伦理与案例督导',
  img: COURSEIMG19,
  url: 'http://xinlizixun.aijiad.com/detail/18025'
}]
export {
  noticeList,
  teacherList,
  heLecturer,
  pcLecturer,
  courseList,
  heCourseSyllabus,
  pcCourseSyllabus,
  pcPolicieList,
  hePolicieList,
  fqaList,
  excellentCourseList
}
